import { AlertColor, IconButton, Link, Menu, Typography } from '@mui/material';
import { getInitials } from '../../../common/commonUtil';
import { getNameFromId, handleCopy } from '../../common/commonUtil';
import EmailIcon from '../../assets/common-assets/email.svg';
import MobileIcon from '../../assets/common-assets/call.svg';
import LocationIcon from '../../assets/common-assets/location_on.svg';
import CompanyIcon from '../../assets/common-assets/account_balance.svg';
import OperationsIcon from '../../assets/common-assets/public.svg';
import TimeIcon from '../../assets/common-assets/access_time.svg';
import { LookupTypes } from '../../../common/lookupTypes';
import Notify from '../../common/notify';
import { useState } from 'react';
import { ReactComponent as ContentCopy } from '../../assets/candidate-assets/content_copy.svg';

interface Props {
  contact: any;
  anchorEl: HTMLElement | null;
  onClose: () => void;
}

const ContactCard = ({ contact, anchorEl, onClose }: Props) => {
  const open = Boolean(anchorEl);
  const [showToast, setShowToast] = useState(false);
  const [toastMsg, setToastMsg] = useState({
    severity: 'success',
    msg: '',
  } as {
    severity: AlertColor;
    msg: string;
  });

  return (
    <>
      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={onClose}
        slotProps={{
          paper: {
            sx: {
              border: '1px solid #bcb9b9 !important',
              boxShadow:
                '4px 4px 6px -1px rgba(0, 0, 0, 0.1), -4px -4px 4px -1px rgba(0, 0, 0, 0.06)',
            },
          },
        }}>
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            gap: '1.5rem',
            padding: '1rem 1.5rem',
          }}>
          <div style={{ display: 'flex', gap: '10px' }}>
            {contact?.profilePicture && contact.profilePicture?.length > 0 ? (
              <img
                className='user-icon'
                style={{
                  height: '5rem',
                  width: '5rem',
                  borderRadius: '50%',
                }}
                src={contact?.profilePicture}
              />
            ) : (
              <div
                className='user-icon'
                style={{
                  backgroundColor: 'rgb(25, 118, 210)',
                  color: 'white',
                  height: '5rem',
                  width: '5rem',
                }}>
                {contact && getInitials(contact)}
              </div>
            )}

            <div style={{ display: 'flex', flexDirection: 'column', gap: '5px' }}>
              <span
                className='menuItem'
                style={{
                  fontSize: 'var(--Font-size-h4, 1.75rem)',
                  fontWeight: 400,
                  color: 'var(--Primary-Dark-grey-500, #333)',
                }}>
                {contact?.firstName} {contact?.lastName}
              </span>
              <span
                style={{
                  fontSize: 'var(--Font-size-h6, 1.1875rem)',
                  fontWeight: 400,
                  color: 'var(--Primary-Dark-grey-500, #333)',
                }}>
                {contact && contact?.roles?.map((role: any) => role.name)}
              </span>
              <span
                style={{
                  fontSize: 'var(--Font-size-P, 1rem)',
                  fontWeight: 400,
                  color: 'var(--Primary-Dark-grey-500, #333)',
                }}>
                {`Work Hours: ${'09:00'} - ${'18:00'}`}
              </span>
            </div>
          </div>
          <div>
            <Typography
              variant='h6'
              sx={{ fontWeight: '600', fontSize: 'var(--Font-size-h6, 1.1875rem)' }}>
              Contact information
            </Typography>
          </div>
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <div>
              <div style={{ display: 'flex', alignItems: 'center', marginBottom: '1.88rem' }}>
                <img src={EmailIcon} style={{ marginRight: '1.12rem' }} />
                <div>
                  <Typography className='feildHeader' sx={{ fontSize: '0.875rem' }}>
                    Email
                  </Typography>
                  <div
                    className='copy-container'
                    style={{ display: 'flex', alignItems: 'center', marginRight: '10px' }}>
                    {contact?.email ? (
                      <>
                        <Link className='copy-label' href={'srinivas.desai@peopletech.com'}>
                          {contact && contact?.email}
                        </Link>
                        <IconButton
                          onClick={() => {
                            setShowToast(true);
                            setToastMsg({ severity: 'success', msg: 'Copied!' });
                            handleCopy(contact.email);
                          }}>
                          <ContentCopy
                            style={{
                              cursor: 'pointer',
                              height: '20px',
                              width: '20px',
                              color: 'grey',
                            }}
                          />
                        </IconButton>
                      </>
                    ) : (
                      <span>-</span>
                    )}
                  </div>
                </div>
              </div>
              <div style={{ display: 'flex', alignItems: 'center', marginBottom: '1.88rem' }}>
                <img src={LocationIcon} style={{ marginRight: '1.12rem' }} />
                <div>
                  <Typography className='feildHeader' sx={{ fontSize: '0.875rem' }}>
                    Location
                  </Typography>
                  <span>Hyderabad</span>
                </div>
              </div>
              <div style={{ display: 'flex', alignItems: 'center', marginBottom: '1.88rem' }}>
                <img src={OperationsIcon} style={{ marginRight: '1.12rem' }} />
                <div>
                  <Typography className='feildHeader' sx={{ fontSize: '0.875rem' }}>
                    Operations
                  </Typography>
                  <span>
                    {contact && getNameFromId(contact?.operations, LookupTypes.TAD_OPERATION)}
                  </span>
                </div>
              </div>
            </div>
            <div>
              <div style={{ display: 'flex', alignItems: 'center', marginBottom: '1.88rem' }}>
                <img src={MobileIcon} style={{ marginRight: '1.12rem' }} />
                <div>
                  <Typography className='feildHeader' sx={{ fontSize: '0.875rem' }}>
                    Mobile
                  </Typography>
                  <div
                    className='copy-container'
                    style={{ display: 'flex', alignItems: 'center', marginRight: '10px' }}>
                    {contact?.phoneNumber ? (
                      <>
                        <Link className='copy-label'>{contact && contact?.phoneNumber}</Link>
                        <IconButton
                          onClick={() => {
                            setShowToast(true);
                            setToastMsg({ severity: 'success', msg: 'Copied!' });
                            handleCopy(contact.phoneNumber);
                          }}>
                          <ContentCopy
                            style={{
                              cursor: 'pointer',
                              height: '20px',
                              width: '20px',
                              color: 'grey',
                            }}
                          />
                        </IconButton>
                      </>
                    ) : (
                      <span>-</span>
                    )}
                  </div>
                </div>
              </div>
              <div style={{ display: 'flex', alignItems: 'center', marginBottom: '1.88rem' }}>
                <img src={CompanyIcon} style={{ marginRight: '1.12rem' }} />
                <div>
                  <Typography className='feildHeader' sx={{ fontSize: '0.875rem' }}>
                    Company
                  </Typography>
                  <span>{contact && getNameFromId(contact.company, LookupTypes.COMPANY)}</span>
                </div>
              </div>
              <div style={{ display: 'flex', alignItems: 'center', marginBottom: '1.88rem' }}>
                <img src={TimeIcon} style={{ marginRight: '1.12rem' }} />
                <div>
                  <Typography className='feildHeader' sx={{ fontSize: '0.875rem' }}>
                    Timezone
                  </Typography>
                  <span>19:37 - Same time zone as you</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Menu>
      <Notify
        open={showToast}
        severity={toastMsg.severity}
        onClose={() => setShowToast(false)}
        message={toastMsg.msg}></Notify>
    </>
  );
};

export default ContactCard;
