import {
  Box,
  Button,
  FormControlLabel,
  IconButton,
  Paper,
  Radio,
  RadioGroup,
  TextField,
  Tooltip,
} from '@mui/material';
import './initial-screening-ratings.scss';
import Rating from '@mui/material/Rating';
import StarIcon from '@mui/icons-material/Star';
import { ReactComponent as CloseIcon } from '../../assets/sjd/close.svg';
import { ReactComponent as AddIcon } from '../../assets/sjd/add.svg';
import GradeIcon from '@mui/icons-material/Grade';
import { useEffect, useMemo, useState } from 'react';
import { TadCandidateDetailsModel } from '../../models/tad-candidate-form-model';
import { filterSjdInfo, getIdFromName } from '../../../common/commonUtil';
import { useFormik } from 'formik';
import {
  tadAddCommentsToCandidate,
  tadUpdateCandidateRating,
} from '../../service/tadCandidateService';
import { LookupTypes } from '../../../common/lookupTypes';
import dateFormat from 'dateformat';
import { TADMODULE } from '../../../constants/common';
import { isTadPermissions } from '../../common/commonUtil';
// import { tadUpdateCandidateRating } from '../../service/tadCandidateService';

interface Props {
  candidate: TadCandidateDetailsModel;
  sjdId: any;
  setLoading: (isLoading: boolean) => void;
  reloadCandidate: () => void;
  alert: (msg: string, success: boolean) => void;
}

interface screeningParameterTYpe {
  parameter?: any;
  rating?: any;
}

const InitialScreeningRatings = ({
  candidate,
  sjdId,
  setLoading,
  reloadCandidate,
  alert,
}: Props) => {
  const [sjdCandidateInfo, setSjdCandidateInfo] = useState({
    sjdId: '',
    candidateId: '',
    candidateStatusId: '',
    qcRating: '',
    profilerRating: '',
    qcScreeningParameter: '[{}]',
    prScreeningParameter: '[{}]',
    prRatingComment: '',
    qcRatingComment: '',
  } as any);
  const [ratingType, setRatingType] = useState('QC');
  const [qcRatingEvents, setQcRatingEvents] = useState([] as any[]);
  const [prRatingEvents, setPrRatingEvents] = useState([] as any[]);

  const saveRating = (req: any) => {
    setLoading(true);
    tadUpdateCandidateRating(
      {
        sjdId: sjdId,
        candidateId: candidate.id,
        qcRating: req.qcRating,
        profilerRating: req.profilerRating,
        qcScreeningParameter: JSON.stringify(addQcscreeningValues),
        prScreeningParameter: JSON.stringify(addPrScreeningValues),
        prRatingComment: req.prRatingComment,
        qcRatingComment: req.qcRatingComment,
      },
      ratingType === 'QC' ? 'qcRating' : 'profilerRating',
    ).then((_res) => {
      ratingType === 'QC'
        ? updateComments(req.qcRatingComment)
        : updateComments(req.prRatingComment);
    });
  };

  const updateComments = (value: any) => {
    tadAddCommentsToCandidate({
      sjdId,
      candidateId: candidate.id,
      description: value,
    })
      .then((_resp) => {
        alert(ratingType === 'QC' ? 'QC rated successfully!' : 'PR rated successfully!', true);
        setLoading(false);
        reloadCandidate();
      })
      .catch((_err) => {
        alert('Something went wrong. Please try later!', false);
      })
      .finally(() => setLoading(false));
  };

  const formik: any = useFormik({
    initialValues: {
      ...sjdCandidateInfo,
    },
    enableReinitialize: true,
    onSubmit: saveRating,
    validate: () => {
      const parameterAndRatingErrors = validateParameterAndRating(
        ratingType === 'QC' ? addQcscreeningValues : addPrScreeningValues,
      );
      return {
        ...parameterAndRatingErrors,
      };
    },
  });

  useEffect(() => {
    if (candidate.sjdCandidateInfo) {
      const info = filterSjdInfo(candidate.sjdCandidateInfo, sjdId);
      setSjdCandidateInfo({ ...info });
    }
    if (candidate.events) {
      setPrRatingEvents(
        candidate.events.filter(
          (event) =>
            event.sjdId &&
            event.eventTypeId ==
              getIdFromName('PROFILER_RATING_ADDED', LookupTypes.CANDIDATE_EVENT_TYPE),
        ),
      );
      setQcRatingEvents(
        candidate.events.filter(
          (event) =>
            event.sjdId &&
            event.eventTypeId == getIdFromName('QC_RATING_ADDED', LookupTypes.CANDIDATE_EVENT_TYPE),
        ),
      );
    }
  }, [candidate]);

  const validateParameterAndRating = (skills: screeningParameterTYpe[]) => {
    const errors: any = {};

    if (!skills.some((item) => item.parameter && item.rating)) {
      errors.errorMsg = 'At least one screening paramter is required';
    }
    skills.forEach((item) => {
      if (item.parameter && !item.rating) {
        errors.errorMsg = 'Rating is required for the entered skill';
      }
    });

    return errors;
  };

  const prScreeningParameters = useMemo(() => {
    return formik.values.prScreeningParameter
      ? JSON.parse(formik.values.prScreeningParameter)
      : [{}];
  }, [formik.values.prScreeningParameter]);

  const qcScreeningParameters = useMemo(() => {
    return formik.values.qcScreeningParameter
      ? JSON.parse(formik.values.qcScreeningParameter)
      : [{}];
  }, [formik.values.qcScreeningParameter]);

  const [addPrScreeningValues, setAddPrScreeningValues] =
    useState<screeningParameterTYpe[]>(prScreeningParameters);
  const [addQcscreeningValues, setQcAddScreeningValues] =
    useState<screeningParameterTYpe[]>(qcScreeningParameters);

  useEffect(() => {
    setAddPrScreeningValues(prScreeningParameters);
  }, [prScreeningParameters]);

  useEffect(() => {
    setQcAddScreeningValues(qcScreeningParameters);
  }, [qcScreeningParameters]);

  const handleClose = (index: number) => {
    if (index != 0) {
      const newList = ratingType === 'QC' ? [...addQcscreeningValues] : [...addPrScreeningValues];
      newList.splice(index, 1);
      if (newList.length == 0) newList.push({});
      const validRatings = newList
        .map((skill) => skill.rating)
        .filter((rating) => rating !== null) as number[];
      formik.setFieldValue(
        ratingType === 'QC' ? 'qcRating' : 'profilerRating',
        validRatings.reduce((sum, rating) => sum + rating, 0) / validRatings.length,
      );
      ratingType === 'QC'
        ? setQcAddScreeningValues(() => [...newList])
        : setAddPrScreeningValues(() => [...newList]);
    }
  };

  const addNewRow = () => {
    ratingType === 'QC'
      ? setQcAddScreeningValues((addQcscreeningValues) => [
          ...addQcscreeningValues,
          { id: addQcscreeningValues.length, parameter: '' },
        ])
      : setAddPrScreeningValues((addPrScreeningValues) => [
          ...addPrScreeningValues,
          { id: addPrScreeningValues.length, parameter: '' },
        ]);
  };
  const handleRatingChange = (index: number, value: string) => {
    const temp = ratingType === 'QC' ? addQcscreeningValues : addPrScreeningValues;
    temp[index].rating = value ? Number(value) : null;
    const validRatings = temp
      .map((skill) => skill.rating)
      .filter((rating) => rating !== null) as number[];
    formik.setFieldValue(
      ratingType === 'QC' ? 'qcRating' : 'profilerRating',
      validRatings.reduce((sum, rating) => sum + rating, 0) / validRatings.length,
    );
    ratingType === 'QC'
      ? setQcAddScreeningValues(() => [...temp])
      : setAddPrScreeningValues(() => [...temp]);
  };

  const handleParameterChange = (index: number, value: string) => {
    const temp = ratingType === 'QC' ? addQcscreeningValues : addPrScreeningValues;
    temp[index].parameter = value ? value : null;
    ratingType === 'QC'
      ? setQcAddScreeningValues(() => [...temp])
      : setAddPrScreeningValues(() => [...temp]);
  };

  // const filteredScreeningValues = addscreeningValues.filter((param) => param.type === ratingType);

  // const displayRatingValue =
  //   ratingType === 'QC'
  //     ? sjdCandidateInfo[0]?.qcRating ?? 5
  //     : sjdCandidateInfo[0]?.profilerRating ?? 5;

  // const currentRatingValue =
  //   ratingType === 'QC'
  //     ? sjdCandidateInfo[0]?.qcRating ?? 0
  //     : sjdCandidateInfo[0]?.profilerRating ?? 0;

  return (
    <Paper style={{ height: '100%' }}>
      <div className='header-container'>
        <div className='header-content-title'>
          <span>Initial Screening Ratings</span>
        </div>
      </div>
      <div style={{ paddingLeft: '1.63rem', paddingRight: '1.37rem', paddingBottom: '1.5rem' }}>
        <div style={{ marginTop: '20px', display: 'flex', flexDirection: 'column' }}>
          <span className='semi-bold'>Provide your inital resume screening ratings</span>
          <span style={{ marginTop: '0.32rem' }}>
            These initial ratings help recruiters in determining the next steps in hiring process
          </span>
        </div>
        <RadioGroup
          sx={{ marginTop: '2rem' }}
          row
          aria-labelledby='demo-controlled-radio-buttons-group'
          name='controlled-radio-buttons-group'
          value={ratingType}
          onChange={(e) => {
            setRatingType(e.target.value);
          }}>
          <FormControlLabel value={'QC'} control={<Radio />} label='Quality Check (QC)' />
          <FormControlLabel value={'PR'} control={<Radio />} label='Profile Rating (PR)' />
        </RadioGroup>

        <div className='semi-bold' style={{ marginTop: '30px' }}>
          <span>{ratingType === 'QC' ? 'Quality Check (QC)' : 'Profile Rating (PR)'}</span>
        </div>

        <div style={{ marginTop: '10px' }}>
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              gap: '1rem',
              padding: '1rem',
            }}
            className='rating'>
            <Tooltip title=''>
              <Rating
                className='rating'
                id='Rating'
                readOnly
                value={ratingType === 'PR' ? formik.values.profilerRating : formik.values.qcRating}
                name='Rating'
                icon={
                  <StarIcon
                    style={{ opacity: 0.55 }}
                    sx={{
                      color:
                        parseFloat(formik.values.profilerRating) <= 2.5 ||
                        parseFloat(formik.values.qcRating) <= 2.5
                          ? '#DC3857'
                          : '#faaf00',
                    }}
                    fontSize='inherit'
                  />
                }
                emptyIcon={<StarIcon className='empty-star' fontSize='inherit' />}
                precision={0.5}
              />
            </Tooltip>
            <span className='header-label'>
              {(ratingType === 'PR' ? formik.values.profilerRating : formik.values.qcRating)
                ? ratingType === 'PR'
                  ? formik.values.profilerRating
                  : formik.values.qcRating
                : 0}{' '}
              / 5
            </span>
          </div>
        </div>

        <div style={{ marginTop: '30px', width: '100%' }}>
          <span className='semi-bold'>Screening Parameters</span>
          {(ratingType === 'PR' ? addPrScreeningValues : addQcscreeningValues).map(
            (skill, index) => (
              <Box key={index} className='box-bg'>
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    gap: '1rem',
                  }}>
                  <TextField
                    disabled={!isTadPermissions(TADMODULE.CANDIDATES, 'write')}
                    sx={{ minWidth: '2rem', marginLeft: '1.5rem' }}
                    variant='outlined'
                    size='small'
                    value={skill?.parameter == undefined ? '' : skill?.parameter}
                    onChange={(e) => {
                      handleParameterChange(index, e.target.value);
                    }}
                  />
                  <Rating
                    id='Rating'
                    disabled={!isTadPermissions(TADMODULE.CANDIDATES, 'write')}
                    value={parseFloat(skill?.rating)}
                    onChange={(event: any) => handleRatingChange(index, event.target.value)}
                    name='Rating'
                    icon={<StarIcon className='filled-star' fontSize='inherit' />}
                    emptyIcon={<StarIcon className='empty-star' fontSize='inherit' />}
                    style={{ gap: '0.5rem', marginLeft: '20px' }}
                    precision={0.5}
                  />
                  <span className='header-label'>
                    {parseFloat(skill?.rating ? skill?.rating : 0)} / 5
                  </span>
                </div>
                <IconButton>
                  <CloseIcon onClick={() => handleClose(index)} />
                </IconButton>
              </Box>
            ),
          )}
          {formik.errors.errorMsg && <div className='form-err-msg'>{formik.errors.errorMsg}</div>}
        </div>
        {isTadPermissions(TADMODULE.CANDIDATES, 'write') && (
          <div style={{ marginTop: '40px', display: 'flex', alignItems: 'center' }}>
            <AddIcon className='add-icon path' onClick={addNewRow} />
            <span style={{ color: '#1976D2', textDecoration: 'underline' }}>Add</span>
          </div>
        )}

        <TextField
          fullWidth
          disabled={!isTadPermissions(TADMODULE.CANDIDATES, 'write')}
          placeholder='Comments'
          multiline
          value={
            ratingType == 'PR'
              ? formik.values.prRatingComment
                ? formik.values.prRatingComment
                : ''
              : formik.values.qcRatingComment
              ? formik.values.qcRatingComment
              : ''
          }
          onChange={(e) =>
            ratingType === 'PR'
              ? formik.setFieldValue('prRatingComment', e.target.value)
              : formik.setFieldValue('qcRatingComment', e.target.value)
          }
          minRows={4}
          sx={{ marginTop: '0.5rem' }}
        />

        <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
          <Button
            onClick={() => {
              formik.handleSubmit();
            }}
            disabled={!isTadPermissions(TADMODULE.CANDIDATES, 'write')}
            style={{
              background: '#1976D2',
              color: '#ffffff',
              marginTop: '20px',
              width: '7rem',
              height: '2.5rem',
            }}>
            Submit
          </Button>
        </div>
        <hr style={{ background: '#868686', marginTop: '20px' }} />
        <div style={{ display: 'flex', marginTop: '30px', width: '100%', gap: '1rem' }}>
          <div style={{ display: 'flex', flexDirection: 'column', width: '50%' }}>
            <span className='semi-bold'>Quality Check Parameters</span>
            <div
              style={{
                marginTop: '10px',
                display: 'flex',
                alignItems: 'center',
                gap: '1rem',
                marginBottom: '0.5rem',
              }}>
              <Rating
                readOnly
                className='rating'
                name='half-rating'
                precision={0.5}
                value={sjdCandidateInfo?.qcRating}
                icon={<StarIcon className='filled-star' fontSize='inherit' />}
                emptyIcon={<StarIcon className='empty-star' fontSize='inherit' />}
                style={{ gap: '0.5rem' }}
              />
              <span className='header-label'>
                {sjdCandidateInfo.qcRating ? sjdCandidateInfo.qcRating : 0} / 5
              </span>
            </div>
            <div>
              {sjdCandidateInfo.qcScreeningParameter &&
                JSON.parse(sjdCandidateInfo.qcScreeningParameter).map((val: any, index: any) => (
                  <div
                    key={index}
                    style={{
                      display: 'flex',
                      backgroundColor: '#F1F4F8',
                      justifyContent: 'space-between',
                      height: '2.3125rem',
                      alignItems: 'center',
                      paddingLeft: '10px',
                      marginBottom: '10px',
                    }}>
                    <span>{val.parameter}</span>
                    <div
                      style={{
                        marginRight: '2rem',
                        gap: '0.3rem',
                        display: 'flex',
                        alignItems: 'center',
                      }}>
                      <GradeIcon style={{ color: '#FDD835' }} />{' '}
                      <span className='header-label'>{val.rating ? val?.rating : 0} / 5</span>
                    </div>
                  </div>
                ))}
            </div>
            <div style={{ marginTop: '30px' }}>
              {qcRatingEvents.map((data) => (
                <>
                  <span className='header-label' style={{ fontWeight: 700, marginTop: '20px' }}>
                    {data.createdBy}
                  </span>
                  <br />
                  <span className='feildHeader' style={{ marginBottom: '1rem' }}>
                    {dateFormat(new Date(data.createdOn), 'dd/mm/yyyy hh:MM TT')}{' '}
                  </span>
                  <br />
                  <span style={{ width: '18.4375rem' }}>{data.description}</span>
                  <br />
                </>
              ))}
            </div>
          </div>

          <div style={{ width: '50%' }}>
            <span className='semi-bold'>Profile Rating Parameters</span>
            <div
              style={{
                marginTop: '10px',
                display: 'flex',
                alignItems: 'center',
                gap: '1rem',
                marginBottom: '0.5rem',
              }}>
              <Rating
                readOnly
                className='rating'
                name='half-rating'
                precision={0.5}
                value={sjdCandidateInfo?.profilerRating}
                icon={<StarIcon className='filled-star' fontSize='inherit' />}
                emptyIcon={<StarIcon className='empty-star' fontSize='inherit' />}
                style={{ gap: '1rem' }}
              />
              <span className='header-label'>
                {sjdCandidateInfo?.profilerRating ? sjdCandidateInfo?.profilerRating : 0} / 5
              </span>
            </div>
            <div>
              {sjdCandidateInfo.prScreeningParameter &&
                JSON.parse(sjdCandidateInfo.prScreeningParameter).map((val: any, index: any) => (
                  <div
                    key={index}
                    style={{
                      display: 'flex',
                      backgroundColor: '#F1F4F8',
                      justifyContent: 'space-between',
                      height: '2.3125rem',
                      alignItems: 'center',
                      paddingLeft: '10px',
                      marginBottom: '10px',
                    }}>
                    <span>{val.parameter}</span>
                    <div
                      style={{
                        marginRight: '2rem',
                        gap: '0.3rem',
                        display: 'flex',
                        alignItems: 'center',
                      }}>
                      <GradeIcon style={{ color: '#FDD835' }} />{' '}
                      <span className='header-label'>{val?.rating ? val?.rating : 0} / 5</span>
                    </div>
                  </div>
                ))}
            </div>
            <div style={{ marginTop: '30px' }}>
              {prRatingEvents.map((data) => (
                <>
                  <span className='header-label' style={{ fontWeight: 700, marginTop: '20px' }}>
                    {data.createdBy}
                  </span>
                  <br />
                  <span className='feildHeader' style={{ marginBottom: '0.94rem' }}>
                    {dateFormat(new Date(data.createdOn), 'dd/mm/yyyy hh:MM TT')}{' '}
                  </span>
                  <br />
                  <span style={{ width: '18.4375rem' }}>{data.description}</span>
                </>
              ))}
            </div>
          </div>
        </div>
      </div>
    </Paper>
  );
};

export default InitialScreeningRatings;
