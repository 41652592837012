import axiosClient from '../../common/axiosClient';

export async function saveTeam(req: any) {
  return axiosClient.post('/tad-team', req);
}

export async function updateTeam(req: any) {
  return axiosClient.patch('/tad-team', req);
}

export async function getAllTeams() {
  return axiosClient.get('/tad-team/all');
}

export async function getTadMyTeam(recruitingManager: any) {
  return axiosClient.get('/tad-team', { params: { recruitingManager } });
}
