import axiosClient from '../common/axiosClient';

export async function getMyTeam() {
  const flag = localStorage.getItem('switch');
  return axiosClient.get('/team', { params: { flag: flag ? flag : false } });
}

export async function addMyTeam(id: number) {
  const flag = localStorage.getItem('switch');
  return axiosClient.post('/team', [id], { params: { flag: flag ? flag : false } });
}

export async function removeMyTeam(id: number) {
  const flag = localStorage.getItem('switch');
  return axiosClient.delete('/team', { params: { memberId: id, flag: flag ? flag : false } });
}
