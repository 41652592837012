import { Checkbox, ListItemText, MenuItem, Select } from '@mui/material';
import { useState } from 'react';

interface Props {
  headers: any;
  selectedHeaders: (value: number[]) => void;
}

const TadTableFilter = ({ headers, selectedHeaders }: Props) => {
  const [selectedColumns, setSelectedColumns] = useState<number[]>(
    headers.map((header: any) => header.id),
  );

  const handleSelectAll = () => {
    const allColumnIds = headers.map((header: any) => header.id);
    setSelectedColumns(allColumnIds);
    selectedHeaders(allColumnIds);
  };

  const handleIndividualToggle = (value: number[]) => {
    setSelectedColumns(value);
    selectedHeaders(value);
  };

  return (
    <div>
      <Select
        className='input-field'
        sx={{ width: '15rem', border: 'none !important' }}
        labelId='demo-mutiple-checkbox-label'
        id='demo-mutiple-checkbox'
        multiple
        value={selectedColumns}
        onChange={(e) => {
          const value = e.target.value as number[];
          const isAllColumns = value.includes(0);

          if (isAllColumns && selectedColumns.length !== headers.length) {
            // Only allow selecting all
            handleSelectAll();
          } else {
            // Normal toggle for individual columns
            const newSelectedColumns = value.map(Number).sort((a, b) => a - b);
            handleIndividualToggle(newSelectedColumns);
          }
        }}
        renderValue={() => {
          return (
            <p>
              {selectedColumns.length === 0 ? (
                <span className='status-box'>Scheduled By</span>
              ) : selectedColumns.length === headers.length ? (
                <span className='status-box'>All Columns</span>
              ) : selectedColumns.length === 1 ? (
                <span className='status-box'>
                  {headers.find((header: any) => header.id === selectedColumns[0])?.label}
                </span>
              ) : (
                <span className='status-box'>Multiple Columns</span>
              )}
            </p>
          );
        }}
        MenuProps={{
          PaperProps: {
            style: {
              maxHeight: '11.5rem',
            },
          },
        }}>
        <MenuItem
          value={0}
          sx={{ padding: 0 }}
          disabled={selectedColumns.length === headers.length}>
          <Checkbox checked={selectedColumns.length === headers.length} />
          <ListItemText primary='All Columns' />
        </MenuItem>
        {headers.map((column: any) => (
          <MenuItem key={column.id} value={column.id} sx={{ padding: 0 }}>
            <Checkbox checked={selectedColumns.includes(column.id)} />
            <ListItemText primary={column.label} />
          </MenuItem>
        ))}
      </Select>
    </div>
  );
};

export default TadTableFilter;
