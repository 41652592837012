import { Visibility, VisibilityOff } from '@mui/icons-material';
import MicrosoftLogin from 'react-microsoft-login';
import {
  Backdrop,
  Button,
  CircularProgress,
  Grid,
  IconButton,
  InputAdornment,
  InputLabel,
  OutlinedInput,
  TextField,
} from '@mui/material';
import { useFormik } from 'formik';
import React, { useState, useEffect } from 'react';
import * as Yup from 'yup';
import { authenticate, login as loginApi } from '../service/securityService';
import useNavigate from './../common/useNavigate';
import Notify from './common/notify';
import globalObject from '../common/global-variables';
import { getProperties } from '../service/commonService';
import Marquee from 'react-fast-marquee';
import { PEOPLETECH_REGEX } from '../constants/common';

const Login = () => {
  localStorage.removeItem('token');
  localStorage.removeItem('refreshtoken');
  const flag = localStorage.getItem('switch');
  const signInSchema = Yup.object().shape({
    username: Yup.string()
      .email('Please enter valid email')
      .required('Email is required')
      .test(
        'emailDomainValidation',
        'Please use Microsoft account to login. Click on the link below.',
        (value) =>
          globalObject.properties.enable_office_integration == 'false' ||
          !PEOPLETECH_REGEX.test(value),
      ),
    password: Yup.string().required('Password is required'),
  });
  const [apiError, setApiError] = useState(false);
  const [showOverlay, setShowOverlay] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [showToast, setShowToast] = useState(false);
  const [navigate, redirect] = useNavigate();
  const [propsLoading, setPropsLoading] = useState(true);
  const [enableMicrosoftLogin, setEnableMicrosoftLogin] = useState('true');
  const params = new URLSearchParams(window.location.search);
  const target = params.get('from');

  const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
  };

  useEffect(() => {
    const promise = !globalObject.properties
      ? getProperties()
      : new Promise((resolve) => {
          resolve({ data: globalObject.properties });
        });
    promise
      .then((resp: any) => {
        globalObject.properties = resp.data;
        setEnableMicrosoftLogin(resp.data.enable_office_integration);
        setPropsLoading(false);
      })
      .catch((err: any) => {
        console.log(err);
      });
  }, []);

  const login = (values: any) => {
    const flag = localStorage.getItem('switch');
    console.log(flag, { ...values, flag });
    setApiError(false);
    setShowOverlay(true);
    loginApi({ ...values, flag: flag ? flag : false })
      .then((resp) => {
        localStorage.setItem('token', resp.data.token);
        if (resp.data.roles.filter((roles: any) => roles.name == 'intern').length > 0) {
          if (target) {
            redirect(atob(target));
          } else {
            navigate(
              [
                {
                  label: 'LMS Dashboard',
                  link: '/lms',
                },
              ],
              true,
            );
          }
        } else {
          if (target) {
            redirect(atob(target));
          } else {
            navigate(
              [
                {
                  label: 'Dashboard',
                  link: '/',
                },
              ],
              true,
            );
          }
        }
      })
      .catch((_err) => {
        setApiError(true);
        setShowOverlay(false);
      });
  };
  const handleToastClose = () => {
    setShowToast(false);
  };

  const authHandler = (err: any, data: any) => {
    console.log('err', err);
    let refreshToken: any;
    for (const key in localStorage) {
      if (key.indexOf('refreshtoken') > -1) {
        const tokenObj: any = localStorage.getItem(key);
        try {
          refreshToken = JSON.parse(tokenObj).secret;
        } catch {
          refreshToken = '';
        }
      }
    }
    if (!err) {
      setShowOverlay(true);
      authenticate({
        idToken: data.idToken,
        accessToken: data.accessToken,
        flag: flag ? flag : false,
      })
        .then((resp) => {
          const config = localStorage.getItem('breadcrumb-config');
          localStorage.clear();
          if (config) localStorage.setItem('breadcrumb-config', config);
          localStorage.setItem('token', resp.data.token);
          localStorage.setItem('refreshtoken', refreshToken);
          if (target) {
            redirect(atob(target));
          } else {
            navigate(
              [
                {
                  label: 'Dashboard',
                  link: '/',
                },
              ],
              true,
            );
          }
        })
        .catch((_err) => {
          setShowOverlay(false);
          setShowToast(true);
        });
    } else {
      setShowToast(true);
    }
  };
  const formik = useFormik({
    initialValues: {
      username: '',
      password: '',
    },
    onSubmit: (values) => {
      login(values);
    },
    validationSchema: signInSchema,
  });

  return (
    <>
      {!propsLoading && (
        <div className='new-login'>
          {enableMicrosoftLogin == 'true' && (
            <Marquee style={{ position: 'absolute', bottom: '0', color: 'white' }}>
              Please allow popups for seamless login experience
            </Marquee>
          )}
          {/* TODO: Create a global loader page */}
          {showOverlay && (
            <Backdrop
              sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
              open={showOverlay}
              onClick={() => setShowOverlay(false)}>
              <CircularProgress color='inherit' />
            </Backdrop>
          )}
          <div style={{ position: 'absolute', top: '20px', left: '20px' }}>
            <img src={require('../assets/ptg-logo.png')} alt='Peopletech-Group' />
          </div>
          {enableMicrosoftLogin == 'true' && (
            <div style={{ position: 'absolute', top: '200px', left: '50px' }}>
              <div
                style={{
                  position: 'relative',
                  marginBottom: '0',
                  color: 'white',
                  fontSize: '40px',
                }}>
                <h1 style={{ margin: '0' }}>
                  <span style={{ fontSize: '50px' }}>Welcome to </span> TAD
                </h1>
              </div>
              <hr
                style={{
                  color: 'white',
                  border: '2px solid white',
                  position: 'relative',
                  left: 0,
                  margin: 0,
                  width: '450px',
                }}></hr>
              <div
                style={{
                  position: 'relative',

                  color: 'white',
                  fontSize: '30px',
                }}>
                <h4 style={{ marginTop: '10px' }}>A Talent Acquisition and Development Platform</h4>
              </div>

              <div className='microsoft-login-text'>
                <MicrosoftLogin
                  withUserData
                  useLocalStorageCache
                  graphScopes={[
                    'Calendars.ReadWrite',
                    'Mail.ReadWrite',
                    'Mail.Send',
                    'OnlineMeetings.ReadWrite',
                    'openid',
                    'profile',
                    'User.Read',
                    'email',
                  ]}
                  clientId={globalObject.properties.microsoft_client_id || ''}
                  authCallback={authHandler}
                  redirectUri='/_blank.html'>
                  <div className='sign-in-div'>
                    <div className='left-block'></div>
                    <div className='middle-block'>
                      <img
                        src={require('../assets/microsoft-logo.jpg')}
                        width='25px'
                        height='25px'
                      />
                      <p>Login with Microsoft Account</p>
                    </div>
                    <div className='right-block'></div>
                  </div>
                </MicrosoftLogin>
              </div>
              <div style={{ color: '#ffffff', marginTop: '10px', paddingLeft: '30px' }}>
                <span
                  style={{
                    cursor: 'pointer',
                    fontFamily: 'Ambit',
                    textDecorationLine: 'underline',
                  }}
                  onClick={() => setEnableMicrosoftLogin('false')}>
                  Not a peopletech user. Click here
                </span>
              </div>
            </div>
          )}{' '}
          <Notify open={showToast} severity='error' onClose={handleToastClose}>
            <span>Authentication Failed!</span>
          </Notify>
          {enableMicrosoftLogin != 'true' && (
            <Grid container spacing={2}>
              <div className='logo-block'></div>
              <Grid item>
                <div className='form-block'>
                  <div className='welcome-text'>
                    <h2>Login</h2>
                  </div>
                  <form onSubmit={formik.handleSubmit} onChange={() => setApiError(false)}>
                    <InputLabel className='input-label' htmlFor='username'>
                      Email
                    </InputLabel>
                    <TextField
                      InputLabelProps={{ className: 'input-label' }}
                      className='new-form-field'
                      variant='outlined'
                      id='username'
                      placeholder='Please enter your user name'
                      value={formik.values.username}
                      error={Boolean(formik.touched.username && formik.errors.username)}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      name='username'
                    />
                    {formik.touched.username && formik.errors.username ? (
                      <div className='err-msg'>{formik.errors.username}</div>
                    ) : null}
                    <InputLabel className='input-label' htmlFor='password-field'>
                      Password
                    </InputLabel>
                    <OutlinedInput
                      id='password-field'
                      type={showPassword ? 'text' : 'password'}
                      className='new-form-field'
                      placeholder='Please enter your password'
                      value={formik.values.password}
                      error={formik.touched.password && Boolean(formik.errors.password)}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      name='password'
                      endAdornment={
                        <InputAdornment position='end'>
                          <IconButton
                            aria-label='toggle password visibility'
                            onClick={() => setShowPassword((show) => !show)}
                            onMouseDown={handleMouseDownPassword}
                            edge='end'>
                            {showPassword ? <VisibilityOff /> : <Visibility />}
                          </IconButton>
                        </InputAdornment>
                      }
                    />
                    {formik.touched.password && formik.errors.password ? (
                      <div className='err-msg'>{formik.errors.password}</div>
                    ) : null}
                    {apiError && <div className='err-msg'>Username or password is invalid</div>}
                    <div className='btn-block'>
                      <Button size='small' type='submit'>
                        Login
                      </Button>
                    </div>
                  </form>
                  <a
                    className='forgot-link'
                    style={{ cursor: 'pointer' }}
                    onClick={() => setEnableMicrosoftLogin('true')}>
                    Click here to login using Microsoft account.
                  </a>
                </div>
              </Grid>
            </Grid>
          )}
        </div>
      )}
      <div style={{ position: 'absolute', bottom: '10px', right: '50px', color: 'white' }}>
        <p>© 2023 People Tech Group all rights reserved</p>
      </div>
    </>
  );
};
export default Login;
