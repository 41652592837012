import { useEffect, useState } from 'react';
import {
  Button,
  FormControl,
  FormLabel,
  MenuItem,
  Select,
  FormControlLabel,
  Radio,
  RadioGroup,
  Drawer,
  Autocomplete,
  TextField,
  InputLabel,
  Typography,
  Menu,
  ListItemIcon,
  ListItemText,
  Chip,
} from '@mui/material';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import { ReactComponent as MoreVertIcon } from '../../../assets/dashboard-assets/more_vert.svg';
import { DateTimePicker } from '@mui/x-date-pickers';
import {
  getAllInterviewLevels,
  tadDeleteInterviewData,
  tadScheduleInterview,
  tadUpdateInterviewDetails,
} from '../../../service/tadCandidateService';
import {
  getNameFromId,
  handleCandidateInitials,
  isTadPermissions,
} from '../../../common/commonUtil';
import { LookupTypes } from '../../../../common/lookupTypes';
import { TADMODULE, TADROLES } from '../../../../constants/common';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import dayjs from 'dayjs';
import dateFormat from 'dateformat';
import { AxiosError, AxiosResponse } from 'axios';
import globalObject from '../../../../common/global-variables';
import { refreshToken } from '../../../../service/securityService';
import { getIdFromName, getInitials } from '../../../../common/commonUtil';
import useNavigate from '../../../../common/useNavigate';
import { ReactComponent as EditIcon } from '../../../assets/sjd/Edit.svg';
import { ReactComponent as DeleteIcon } from './../../../assets/sjd/delete_outline.svg';
import { Link } from 'react-router-dom';

interface Props {
  sjd: any;
  candidate: any;
  setLoading: (isLoading: boolean) => void;
  reloadCandidate: () => void;
  alert: (msg: string, success: boolean) => void;
}

const CandidateInterviewes = ({ candidate, setLoading, reloadCandidate, alert, sjd }: Props) => {
  const [navigate] = useNavigate();
  const [menuOpen, setMenuOpen] = useState(false);
  const [interViewLevels, setInterviewLevels] = useState([] as any[]);
  const [selectedInterview, setSelectedInterview] = useState({} as any);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [evaluators, setEvaluators] = useState([] as any[]);
  useEffect(() => {
    const e: any[] = [];
    candidate?.sjdCandidateInfo
      ?.filter((sjdInfo: any) => sjdInfo.sjdId == sjd.id)
      .forEach((sjd: any) => {
        sjd?.hiringTeam?.forEach((teamUser: any) => {
          if (teamUser.roles?.some((role: any) => role.name === TADROLES.EVALUATOR)) {
            e.push(teamUser);
          }
        });
      });
    setEvaluators(e);
  }, [candidate]);

  const isLeadOrRecruiter = (user: any) => {
    const ids: any[] = [
      getIdFromName(TADROLES.LEAD, LookupTypes.TAD_ROLE),
      getIdFromName(TADROLES.RECRUITER, LookupTypes.TAD_ROLE),
    ];
    return user.roles.filter((r: any) => ids.includes(r.id)).length > 0;
  };

  const actionsList = [
    { name: 'Edit', icon: EditIcon },
    { name: 'Delete', icon: DeleteIcon },
  ];

  const handleConfirmDelete = (interviewToDelete: any) => {
    const token = localStorage.getItem('refreshtoken');
    const promise =
      globalObject.properties.enable_microsoft_meeting == 'true'
        ? refreshToken(token || '')
        : new Promise((resolve) => {
            resolve({ data: {} });
          });
    setLoading(true);
    promise
      .then((resp: any) => {
        tadDeleteInterviewData(
          {
            sjdId: interviewToDelete.sjdId,
            candidateId: interviewToDelete.candidateId,
            idToBeDeleted: interviewToDelete.id,
          },
          resp.data.access_token,
        )
          .then((_resp) => {
            setLoading(false);
            alert('Interview schedule deleted successfully', true);
          })
          .catch((_err) => {
            alert('Something went wrong. Please try again later!', false);
          })
          .finally(() => {});
      })
      .catch((_err) => {
        alert('Something went wrong. Please try again later!', false);
      });
  };

  const validationSchema = Yup.object().shape({
    interviewLevel: Yup.string().required(),
    evaluator: Yup.object().required(),
    startTime: Yup.string().required(),
    isOnlineMeeting: Yup.boolean().required(),
  });

  const formik = useFormik({
    initialValues: {
      interviewLevel: '',
      evaluator: '',
      startTime: '',
      endTime: '',
      isExisting: false,
      isOnlineMeeting: undefined,
    },
    onSubmit: () => {
      interviewSchedule(formik.values);
    },
    validationSchema,
  });

  const handleEdit = (id: number) => {
    const obj: any = candidate.interview?.filter((interview: any) => interview.id == id);
    formik.setValues({
      ...obj[0],
      isExisting: true,
    });
  };
  const interviewSchedule = (req: any) => {
    console.log('token before req=> ', req);

    const request = {
      ...req,
      sjdId: sjd.id,
      sjdName: sjd.name,
      candidateId: candidate.id,
      candidateEmail: candidate.email,
      candidateName: candidate.name,
      location: sjd.jobLocation,
      recruiterEmail: globalObject.userObject.email,
      recruiterName: globalObject.userObject.firstName + ' ' + globalObject.userObject.lastName,
      hiringTeamEmails: sjd.hiringTeam
        .filter((h: any) => isLeadOrRecruiter(h))
        .map((u: any) => u.email),
    };
    console.log('token after req=> ', request);
    const token = localStorage.getItem('refreshtoken');
    console.log('token => ', token);
    const promise =
      globalObject.properties.enable_microsoft_meeting == 'true'
        ? refreshToken(token || '')
        : new Promise((resolve) => {
            resolve({ data: {} });
          });
    setLoading(true);
    promise
      .then((resp: any) => {
        localStorage.setItem('refreshtoken', resp.data.refresh_token);
        const apiCall: Promise<AxiosResponse<any, any>> = req.isExisting
          ? tadUpdateInterviewDetails(request, false, resp.data.access_token)
          : tadScheduleInterview(request, resp.data.access_token);
        apiCall
          .then((_resp) => {
            setMenuOpen(false);
            reloadCandidate();
            alert('Interview scheduled and e-mail has been sent successfully!', true);
            formik.resetForm();
          })
          .catch((err: AxiosError<{ message?: string }>) => {
            let msg = 'Something went wrong. Please try later!';
            if (err && err.response && err.response.data) msg = err.response.data.message || msg;
            alert(msg, false);
          })
          .finally(() => setLoading(false));
      })
      .catch((_err) => {
        alert('Your session expired. Please login again!', true);
        setTimeout(() => {
          setLoading(false);
          navigate([
            {
              label: 'Login',
              link: '/login',
            },
          ]);
        }, 2000);
      });
  };

  const DrawerList = (
    <div
      style={{
        position: 'relative',
        margin: '2.5rem 1.81rem 0rem 1.81rem',
      }}>
      <h2 style={{ margin: '0', paddingBottom: '16px' }}>Schedule an Interview</h2>
      <IconButton
        aria-label='close'
        onClick={() => {
          setInterviewLevels([]);
          formik.resetForm();
          setMenuOpen(false);
        }}
        sx={{
          position: 'absolute',
          right: 8,
          top: 8,
          color: (theme) => theme.palette.grey[500],
        }}>
        <CloseIcon />
      </IconButton>
      <hr style={{ color: '#C1C0C0', marginLeft: 'rem' }}></hr>

      <div style={{ marginBottom: '1rem', display: 'flex', flexDirection: 'row' }}>
        <FormControl fullWidth>
          <FormLabel
            style={{
              color: '#333',
              fontFamily: 'Roboto',
              fontSize: '1rem',
              fontWeight: 400,
              lineHeight: '1.5625rem',
            }}>
            Choose Interview Level
          </FormLabel>
          <Select
            disabled={interViewLevels.length > 0 ? false : true}
            fullWidth
            className='input-field'
            name='interviewLevel'
            value={formik.values.interviewLevel}
            onChange={(e) => formik.setFieldValue('interviewLevel', e.target.value)}
            style={{ height: '2.5rem' }}>
            {interViewLevels.map((level: any) => (
              <MenuItem
                key={level.name}
                value={getNameFromId(level.name, LookupTypes.TAD_INTERVIEW_MAIN_SUBSTAGE)}>
                {getNameFromId(level.name, LookupTypes.TAD_INTERVIEW_MAIN_SUBSTAGE)}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </div>

      <FormControl component='fieldset' style={{ marginBottom: '1rem' }}>
        <FormLabel
          style={{
            color: '#333',
            fontFamily: 'Roboto',
            fontSize: '1rem',
            fontWeight: 400,
            lineHeight: '1.5625rem',
            marginLeft: '0.5rem',
          }}>
          Interview Mode
        </FormLabel>
        <RadioGroup
          row
          aria-labelledby='demo-controlled-radio-buttons-group'
          name='controlled-radio-buttons-group'
          value={formik.values.isOnlineMeeting}
          onChange={(e) => formik.setFieldValue('isOnlineMeeting', e.target.value)}>
          <FormControlLabel value={true} control={<Radio />} label='Online' />
          <FormControlLabel value={false} control={<Radio />} label='In Person' />
        </RadioGroup>
      </FormControl>

      <div style={{ marginBottom: '1rem', display: 'flex', flexDirection: 'row' }}>
        <FormControl fullWidth>
          <FormLabel
            style={{
              color: '#333',
              fontFamily: 'Roboto',
              fontSize: '1rem',
              fontWeight: 400,
              lineHeight: '1.5625rem',
            }}>
            Choose Evaluator
          </FormLabel>
          <Autocomplete
            className='input-field'
            id='combo-box-demo'
            options={evaluators}
            size='small'
            limitTags={1}
            value={formik.values.evaluator}
            getOptionLabel={(option: any) =>
              option ? option.firstName + ' ' + option.lastName : ''
            }
            onBlur={() => formik.setFieldTouched('evaluator', true, true)}
            isOptionEqualToValue={(option: any, value: any) => option.id && option.id == value.id}
            filterSelectedOptions
            renderInput={(params) => (
              <TextField placeholder='Choose Evaluator' {...params} label='' />
            )}
            onChange={(_e, val) => {
              formik.setFieldValue('evaluator', val);
            }}
          />
        </FormControl>
      </div>

      <div style={{ marginBottom: '1rem', display: 'flex', flexDirection: 'row' }}>
        <div style={{ width: '28%', minWidth: '296px' }}>
          <InputLabel className='field-label' id='startTime'>
            Start Date & Time
          </InputLabel>

          <DateTimePicker
            disablePast
            sx={{ width: '100%' }}
            value={dayjs(formik.values.startTime)}
            format='DD/MM/YYYY hh:mm A'
            className='tad-date-picker'
            onChange={(value) => {
              formik.setFieldValue('startTime', dateFormat(value?.toString(), 'isoDateTime'), true);
            }}
          />
        </div>

        <div className='interview-field' style={{ width: '28%', minWidth: '296px' }}>
          <InputLabel className='field-label' id='endTime'>
            End Date & Time
          </InputLabel>
          <DateTimePicker
            sx={{ width: '100%' }}
            value={dayjs(formik.values.endTime)}
            format='DD/MM/YYYY hh:mm A'
            className='tad-date-picker'
            onChange={(value) => {
              formik.setFieldValue('endTime', dateFormat(value?.toString(), 'isoDateTime'), true);
            }}
          />
        </div>
      </div>

      <hr style={{ color: '#C1C0C0', marginLeft: 'rem', marginRight: '1.25rem' }}></hr>

      <div style={{ display: 'flex', justifyContent: 'flex-end', marginTop: '20px' }}>
        <Button
          sx={{
            width: '7.375rem',
            height: '2.5rem',
            borderRadius: '5px',
            border: '1px solid #2D7DFE',
            backgroundColor: '#FFF',
            color: 'black',
            textTransform: 'none',
            fontSize: '1rem',
            fontWeight: 400,
          }}
          onClick={() => setMenuOpen(false)}
          style={{ marginRight: '10px' }}>
          Cancel
        </Button>
        <Button
          sx={{
            textTransform: 'none',
            fontWeight: 400,
            fontSize: '1rem',
            width: '7.375rem',
            height: '2.5rem',
            color: 'white !important',
            background: '#2D7DFE !important',
          }}
          color='primary'
          onClick={() => {
            console.log(formik.values);
            formik.handleSubmit();
          }}>
          Schedule
        </Button>
      </div>
    </div>
  );

  return (
    <>
      <div
        style={{
          height: '61px',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          backgroundColor: 'var(--Secondary-Grey-2, #E7E7E7)',
        }}>
        <div style={{ display: 'flex' }}>
          <span className='heading' style={{ fontSize: '18px', marginLeft: '1.81rem' }}>
            Interviews
          </span>
        </div>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          {isTadPermissions(TADMODULE.CANDIDATES, 'write') && (
            <span
              style={{
                marginRight: '2rem',
                width: '1.5rem',
                height: '1.5rem',
                flexShrink: 0,
                fontSize: '1.5rem',
                lineHeight: '1.5rem',
                display: 'flex',
                alignItems: 'center',
                cursor: 'pointer',
              }}
              onClick={() => {
                if (sjd) {
                  getAllInterviewLevels(sjd.id)
                    .then((res) => setInterviewLevels(res.data))
                    .catch((_err) => {});
                }
                setMenuOpen(true);
              }}>
              +
            </span>
          )}

          <MoreVertIcon style={{ marginRight: '10px' }} />
        </div>
      </div>

      <div
        style={{
          padding: '1.37rem 1.38rem 1rem 1.63rem',
          backgroundColor: 'var(--White-Color, #FFF',
        }}>
        {candidate.interview?.length > 0 ? (
          candidate.interview?.map((details: any, index: any) => (
            <div
              key={index}
              style={{
                borderRadius: '0.3125rem',
                padding: '1rem 1rem 1rem 1rem',
                backgroundColor: 'var(--Secondary-colors-Grey-3, #F1F4F8)',
                marginBottom: '0.5rem',
              }}>
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                }}>
                <div style={{ display: 'flex', gap: '1.0rem', alignItems: 'center' }}>
                  <span
                    style={{
                      fontSize: '1rem',
                      fontWeight: '600',
                    }}>
                    {`${details.interviewLevel} with ${details.evaluator.firstName} ${details.evaluator.lastName}`}
                  </span>
                  <Chip
                    label={details?.feedback ? 'Feedback Submitted' : 'Scheduled'}
                    style={{
                      backgroundColor: details?.feedback ? '#F4C1CB' : 'rgba(122, 224, 139, 0.59)',
                      borderRadius: '0.3125rem',
                      color: 'var(--Primary-Dark-grey-500, #333)',
                      fontWeight: '400',
                      fontSize: '1rem',
                    }}
                  />
                </div>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <IconButton
                    onClick={(e) => {
                      setSelectedInterview(details.id);
                      setAnchorEl(e.currentTarget);
                    }}>
                    <MoreVertIcon />
                  </IconButton>
                  <Menu
                    anchorEl={anchorEl}
                    open={Boolean(anchorEl)}
                    onClose={() => setAnchorEl(null)}>
                    {actionsList.map((action, index) => (
                      <MenuItem
                        key={index}
                        onClick={() => {
                          setAnchorEl(null);
                          if (action.name === 'Delete') {
                            handleConfirmDelete(details);
                          } else if (action.name === 'Edit') {
                            handleEdit(selectedInterview);
                            getAllInterviewLevels(sjd.id)
                              .then((res) => setInterviewLevels(res.data))
                              .catch((_err) => {});
                            setMenuOpen(true);
                          }
                        }}>
                        <ListItemIcon>
                          <action.icon
                            style={{
                              color: '#1976d2',
                            }}
                          />
                        </ListItemIcon>
                        <ListItemText primary={action.name} />
                      </MenuItem>
                    ))}
                  </Menu>
                </div>
              </div>

              <div style={{ display: 'flex', justifyContent: 'start' }}>
                <span
                  className='heading'
                  style={{
                    fontSize: '1rem',
                    color: 'var(--Secondary-colors-Grey, #868686)',
                  }}>
                  {details.startTime ? dayjs(details.startTime).format('DD/MM/YYYY hh:mm A') : ''}
                </span>
              </div>

              <div style={{ display: 'flex', justifyContent: 'start', marginTop: '0.5rem' }}>
                <div
                  className='user-icon'
                  style={{
                    backgroundColor: 'rgb(25, 118, 210)',
                    color: 'white',
                  }}>
                  {handleCandidateInitials(details.candidateName)}
                </div>
                <div
                  className='user-icon'
                  style={{
                    backgroundColor: 'rgb(25, 118, 210)',
                    color: 'white',
                  }}>
                  {getInitials(details?.recruiter)}
                </div>
                <div
                  className='user-icon'
                  style={{
                    backgroundColor: 'rgb(25, 118, 210)',
                    color: 'white',
                  }}>
                  {getInitials(details?.evaluator)}
                </div>
              </div>
              {details.isOnlineMeeting == true ? (
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                  }}>
                  <Typography sx={{ fontSize: '1rem', fontWeight: '400' }}>
                    Online Via <b>Microsoft Teams</b> For <b>{details.sjdName}</b> position
                  </Typography>
                  {details?.feedback == null && (
                    <Link to={details.meetingLink}>
                      <Button
                        variant='contained'
                        color='primary'
                        sx={{
                          width: '9rem',
                          height: 'var(--Font-size-h2, 2rem)',
                          textTransform: 'capitalize',
                        }}>
                        Join Meeting
                      </Button>
                    </Link>
                  )}
                </div>
              ) : (
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    marginTop: '0.5rem',
                  }}>
                  <span className='heading' style={{ fontSize: '1rem' }}>
                    In person interview for <b>{details.sjdName}</b> position
                  </span>
                </div>
              )}
            </div>
          ))
        ) : (
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
            }}>
            No interviews found
          </div>
        )}
      </div>

      <Drawer
        sx={{ width: '21.0625rem', height: '64rem', flexShrink: 0 }}
        anchor='right'
        open={menuOpen}
        onClose={() => setMenuOpen(false)}>
        {DrawerList}
      </Drawer>
    </>
  );
};

export default CandidateInterviewes;
