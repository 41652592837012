import React, { useEffect, useState } from 'react';
import './login.scss';
import ptglogo from './../assets/login/ptglogo.png';
import microsoft from './../assets/login/Microsoft.png';
import MicrosoftLogin from 'react-microsoft-login';
import {
  AlertColor,
  Backdrop,
  Button,
  Dialog,
  IconButton,
  InputAdornment,
  Link,
  OutlinedInput,
  TextField,
  Typography,
} from '@mui/material';
import { useFormik } from 'formik';
import { authenticate, login as loginApi } from '../../service/securityService';
import useNavigate from '../../common/useNavigate';
import { ReactComponent as Visibility } from './../assets/login/visibility.svg';
import { ReactComponent as VisibilityOff } from './../assets/login/visibility_off.svg';
import globalObject from '../../common/global-variables';
import { getProperties } from '../../service/commonService';
import tadLoader from './../assets/common-assets/Whole animation.gif';
import { ReactComponent as WhiteCircleIcon } from '../assets/login/whiteCircleIcon.svg';
import { ReactComponent as PersonIcon } from '../assets/login/person_outline.svg';
import { ReactComponent as LocationSearchingIcon } from '../assets/login/location_searching.svg';
import { ReactComponent as LockIcon } from '../assets/login/lock_open.svg';
import { ReactComponent as TadLogo } from '../assets/login/tad-logo.svg';
import * as Yup from 'yup';
import Notify from '../common/notify';
import TermsOfService from '../terms-and-policies/TermsOfService';
import PrivacyPolicy from '../terms-and-policies/PrivacyPolicy';
import { verifyEmail } from '../service/tadSecurityService';

function TadLogin() {
  localStorage.removeItem('token');
  localStorage.removeItem('refreshtoken');
  const flag = localStorage.getItem('switch');

  const signInSchema = Yup.object().shape({
    username: Yup.string()
      .matches(
        /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{3,}$/,
        'Please enter a valid email address',
      )
      .required('Email is required'),
    password: Yup.string().required('Password is required'),
  });

  const forgotPasswordSchema = Yup.object().shape({
    username: Yup.string()
      .matches(
        /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{3,}$/,
        'Please enter a valid email address',
      )
      .required('Email is required'),
  });

  const [navigate, redirect] = useNavigate();
  const params = new URLSearchParams(window.location.search);
  const target = params.get('from');
  const [showPassword, setShowPassword] = useState(false);
  const [showOverlay, setShowOverlay] = useState(false);
  const [microsoftLoginVisible, setMicrosoftLoginVisible] = useState(false);
  const [showToast, setShowToast] = useState(false);
  const [toastMsg, setToastMsg] = useState({
    severity: 'success',
    msg: '',
  } as {
    severity: AlertColor;
    msg: string;
  });
  const [showForgotPassword, setShowForgotPassword] = useState(false);

  const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
  };

  const formik = useFormik({
    initialValues: {
      username: '',
      password: '',
    },
    onSubmit: (values) => {
      login(values);
    },
    validationSchema: signInSchema,
  });

  const forgotPasswordFormik = useFormik({
    initialValues: {
      username: '',
    },
    onSubmit: (values) => {
      handleForgotPassword(values);
    },
    validationSchema: forgotPasswordSchema,
  });

  const [openDialog, setOpenDialog] = useState(false);
  const [dialogContent, setDialogContent] = useState(null);

  const handleOpenDialog = (content: any) => {
    setDialogContent(content);
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  const handleToastClose = (event?: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }
    setShowToast(false);
  };

  useEffect(() => {
    const promise = !globalObject.properties
      ? getProperties()
      : new Promise((resolve) => {
          resolve({ data: globalObject.properties });
        });
    promise
      .then((resp: any) => {
        globalObject.properties = resp.data;
        setMicrosoftLoginVisible(true);
        console.log(globalObject.properties?.microsoft_client_id);
      })
      .catch((err: any) => {
        console.log(err);
      });
  }, []);

  const authHandler = (err: any, data: any) => {
    let refreshToken: any;
    for (const key in localStorage) {
      if (key.indexOf('refreshtoken') > -1) {
        const tokenObj: any = localStorage.getItem(key);
        try {
          refreshToken = JSON.parse(tokenObj).secret;
        } catch {
          refreshToken = '';
        }
      }
    }
    if (!err) {
      setShowOverlay(true);
      authenticate({ idToken: data.idToken, accessToken: data.accessToken, flag: flag })
        .then((resp) => {
          const config = localStorage.getItem('breadcrumb-config');
          const flag = localStorage.getItem('switch');
          localStorage.clear();
          if (config) localStorage.setItem('breadcrumb-config', config);
          localStorage.setItem('switch', flag ? flag : 'false');
          localStorage.setItem('token', resp.data.token);
          localStorage.setItem('refreshtoken', refreshToken);
          setTimeout(() => {
            if (target) {
              redirect(atob(target));
            } else {
              navigate(
                [
                  {
                    label: 'Dashboard',
                    link: '/',
                  },
                ],
                true,
              );
            }
          }, 4000);
        })
        .catch((_err) => {
          setShowOverlay(false);
        });
    }
  };

  const login = (values: any) => {
    const flag = localStorage.getItem('switch');
    setShowOverlay(true);
    loginApi({ ...values, flag: flag ? (flag === 'true' ? true : false) : false })
      .then((resp) => {
        localStorage.setItem('token', resp.data.token);
        localStorage.setItem('login', 'true');
        const isIntern = resp.data.roles.some((role: any) => role.name === 'intern');
        setTimeout(() => {
          if (target) {
            redirect(atob(target));
          } else {
            if (isIntern) {
              navigate(
                [
                  {
                    label: 'LMS Dashboard',
                    link: '/lms',
                  },
                ],
                true,
              );
            } else {
              navigate(
                [
                  {
                    label: 'NewDashboard',
                    link: '/',
                  },
                ],
                true,
              );
            }
          }
        }, 4000);
      })
      .catch((err) => {
        console.log(err);
        setShowOverlay(false);
        setShowToast(true);
        setToastMsg({
          severity: 'error',
          msg: 'Login failed. Invalid Email or Password',
        });
      });
  };

  const handleForgotPassword = (values: any) => {
    setShowOverlay(true);
    verifyEmail(values)
      .then((resp) => {
        console.log('respnse:', resp.data);
        setShowToast(true);
        setToastMsg({
          severity: 'success',
          msg: 'Password reset email sent',
        });
        setShowOverlay(false);
      })
      .catch((_err) => {
        setShowOverlay(false);
        setShowToast(true);
        setToastMsg({
          severity: 'error',
          msg: 'Failed to send password reset email. Try again later.',
        });
      });
  };

  return (
    <div className='login-background'>
      <div className='login-container'>
        <div className='div-container'>
          <div className='left-login-div'>
            <div style={{ flexShrink: 0, alignSelf: 'flex-start' }}>
              <img className='ptg-logo' src={ptglogo} alt='peopletech-logo'></img>
            </div>
            <div className='text-container'>
              <div className='text-header'>Talent Acquisition & Development</div>
              <div className='text-header-1'>How it works?</div>
              <div className='text-content'>
                <div className='text-icon-container'>
                  <div className='text-icon'>
                    <WhiteCircleIcon className='icons-bg-img' />
                    <PersonIcon className='login-icons' />
                  </div>
                  <Typography sx={{ fontSize: 'var(--Font-size-h6, 1.1875rem)' }}>
                    Log In to Manage Your Talent Pipeline
                  </Typography>
                </div>
                <div className='text-icon-container'>
                  <div className='text-icon'>
                    <WhiteCircleIcon className='icons-bg-img' />
                    <LocationSearchingIcon className='login-icons' />
                  </div>
                  <Typography sx={{ fontSize: 'var(--Font-size-h6, 1.1875rem)' }}>
                    Seamlessly Track Your Applicants
                  </Typography>
                </div>
                <div className='text-icon-container'>
                  <div className='text-icon'>
                    <WhiteCircleIcon className='icons-bg-img' />
                    <LockIcon className='login-icons' />
                  </div>
                  <Typography sx={{ fontSize: 'var(--Font-size-h6, 1.1875rem)' }}>
                    Unlock Your Recruiting Power
                  </Typography>
                </div>
              </div>
            </div>
          </div>
          <div className='right-login-div'>
            <div className='sign-in-container'>
              <TadLogo className='sign-in-logo' />

              <Typography className='sign-in-header' variant='h5' component='h1'>
                {showForgotPassword ? 'Forgot Password?' : 'Sign In'}
              </Typography>
              <div className='sign-in-content'>
                {!showForgotPassword ? (
                  <form onSubmit={formik.handleSubmit}>
                    <TextField
                      className={
                        formik.touched.username && formik.errors.username
                          ? 'sign-in-fields-error'
                          : 'sign-in-fields'
                      }
                      placeholder='Email*'
                      variant='outlined'
                      value={formik.values.username}
                      error={Boolean(formik.touched.username && formik.errors.username)}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      name='username'
                    />
                    {formik.touched.username && formik.errors.username ? (
                      <div className='error-msg'>{formik.errors.username}</div>
                    ) : null}
                    <OutlinedInput
                      className={
                        formik.touched.password && formik.errors.password
                          ? 'sign-in-fields-error'
                          : 'sign-in-fields'
                      }
                      placeholder='Password*'
                      type={showPassword ? 'text' : 'password'}
                      value={formik.values.password}
                      error={formik.touched.password && Boolean(formik.errors.password)}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      name='password'
                      endAdornment={
                        <InputAdornment position='end'>
                          <IconButton
                            size='small'
                            aria-label='toggle password visibility'
                            onClick={() => setShowPassword((show) => !show)}
                            onMouseDown={handleMouseDownPassword}
                            edge='end'>
                            {showPassword ? (
                              <Visibility className='my-svg-icon' />
                            ) : (
                              <VisibilityOff className='my-svg-icon' />
                            )}
                          </IconButton>
                        </InputAdornment>
                      }
                    />
                    {formik.touched.password && formik.errors.password ? (
                      <div className='error-msg'>{formik.errors.password}</div>
                    ) : null}
                    <Button fullWidth type='submit' className='sign-in-button'>
                      Sign In
                    </Button>
                  </form>
                ) : (
                  <form onSubmit={forgotPasswordFormik.handleSubmit}>
                    <Typography
                      sx={{
                        color: '#fff',
                        width: '100%',
                        fontFamily: 'Roboto',
                        lineHeight: '150%',
                      }}>
                      Please enter a valid email address associated with your account to reset your
                      password.
                    </Typography>
                    <TextField
                      className={
                        forgotPasswordFormik.touched.username &&
                        forgotPasswordFormik.errors.username
                          ? 'sign-in-fields-error'
                          : 'sign-in-fields'
                      }
                      placeholder='Email*'
                      variant='outlined'
                      value={forgotPasswordFormik.values.username}
                      error={Boolean(
                        forgotPasswordFormik.touched.username &&
                          forgotPasswordFormik.errors.username,
                      )}
                      onChange={forgotPasswordFormik.handleChange}
                      onBlur={forgotPasswordFormik.handleBlur}
                      name='username'
                      sx={{ marginTop: '1.25rem' }}
                    />
                    {forgotPasswordFormik.touched.username &&
                    forgotPasswordFormik.errors.username ? (
                      <div className='error-msg'>{forgotPasswordFormik.errors.username}</div>
                    ) : null}
                    <Button fullWidth type='submit' className='sign-in-button'>
                      Continue
                    </Button>
                    <Link
                      onClick={() => setShowForgotPassword(false)}
                      sx={{
                        textDecoration: 'underline',
                        color: '#fff',
                        fontFamily: 'Roboto',
                        fontSize: '1rem',
                        cursor: 'pointer',
                      }}>
                      Return to Login
                    </Link>
                  </form>
                )}
                {!showForgotPassword && (
                  <div>
                    <Typography
                      variant='body2'
                      style={{ color: '#FFFFFF', textDecoration: 'underline', cursor: 'pointer' }}
                      onClick={() => setShowForgotPassword(true)}>
                      Forgot Password?
                    </Typography>
                  </div>
                )}
                <div className='divider-container'>
                  <div className='divider-line'></div>
                  <Typography className='divider-text'>or continue with</Typography>
                  <div className='divider-line'></div>
                </div>
                <Button className='microsoft-login-btn'>
                  {microsoftLoginVisible && (
                    <MicrosoftLogin
                      withUserData
                      useLocalStorageCache
                      graphScopes={[
                        'Calendars.ReadWrite',
                        'Mail.ReadWrite',
                        'Mail.Send',
                        'OnlineMeetings.ReadWrite',
                        'openid',
                        'profile',
                        'User.Read',
                        'email',
                      ]}
                      clientId={
                        globalObject.properties ? globalObject.properties.microsoft_client_id : ''
                      }
                      authCallback={authHandler}
                      redirectUri='/_blank.html'>
                      <div style={{ display: 'flex' }}>
                        <span className='microsoft-login-btn-text'>Login with</span>
                        <img className='microsoft-login-btn-img' src={microsoft} />
                      </div>
                      <div className='right-block'></div>
                    </MicrosoftLogin>
                  )}
                </Button>
                <div className='terms-conditions'>
                  By clicking continue, you agree to our{' '}
                  <Link
                    component='button'
                    onClick={() => handleOpenDialog('terms')}
                    sx={{ cursor: 'pointer' }}
                    color={'#fff'}
                    aria-label='Terms of Service'>
                    Terms of Service
                  </Link>{' '}
                  and{' '}
                  <Link
                    component='button'
                    onClick={() => handleOpenDialog('privacy')}
                    sx={{ cursor: 'pointer' }}
                    color={'#fff'}
                    aria-label='Privacy Policy'>
                    Privacy Policy
                  </Link>
                  <Dialog
                    open={openDialog}
                    onClose={handleCloseDialog}
                    maxWidth='xl'
                    PaperProps={{
                      sx: {
                        width: '44.3125rem',
                        height: '48rem',
                        margin: 'auto',
                        paddingBottom: '15px',
                      },
                    }}>
                    {dialogContent === 'terms' && (
                      <TermsOfService handleCloseDialog={handleCloseDialog} />
                    )}
                    {dialogContent === 'privacy' && (
                      <PrivacyPolicy handleCloseDialog={handleCloseDialog} />
                    )}
                  </Dialog>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className='login-footer'>© 2024 People Tech Group all rights reserved</div>
      </div>
      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={showOverlay}
        onClick={() => setShowOverlay(false)}>
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            background: '#fff',
            alignItems: 'center',
            paddingBottom: '10px',
          }}>
          <img src={tadLoader} alt='Loading' style={{ width: '14rem', height: '7rem' }} />
          <span
            style={{
              color: 'var(--Primary-Dark-grey-500,#333)',
              fontFamily: 'Roboto',
              fontSize: 'var(--Font-size-small, 1.75rem)',
              fontStyle: 'normal',
              fontWeight: '500',
              letterSpacing: '0.42rem',
              textAlign: 'center',
            }}>
            LOADING...
          </span>
        </div>
        {/* <div className='loader-container'>
          <div className='loader-line'>
            
          </div>
        </div> */}
      </Backdrop>
      <Notify
        open={showToast}
        severity={toastMsg.severity}
        onClose={handleToastClose}
        message={toastMsg.msg}></Notify>
    </div>
  );
}

export default TadLogin;
