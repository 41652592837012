import axiosClient from '../../common/axiosClient';

export async function saveTadQuestionnaire(req: any, sjdId: any) {
  return axiosClient.post('/tad-questionnaire/', req, {
    params: {
      sjdId: sjdId,
    },
  });
}

export async function tadSaveCandidateAnswers(answers: any) {
  return axiosClient.put('/tad-questionnaire/answer', answers);
}
