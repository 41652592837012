import {
  Box,
  Table,
  Typography,
  TableContainer,
  Paper,
  TableBody,
  TableRow,
  TableCell,
  Button,
  List,
  ListItem,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  MenuItem,
  TextareaAutosize,
  Select,
} from '@mui/material';
import React, { useState } from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';

const TadJobDefinitionApprovalRequest: React.FC = () => {
  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleSubmit = () => {
    // Handle form submission logic here
    // console.log('Reason:', reason);
    // console.log('Message:', message);
    handleClose();
  };

  const validationReasonSchema = Yup.object().shape({
    reason: Yup.string().required('Please select a reason.'),
    message: Yup.string().max(500, 'Message cannot exceed 500 characters.'),
  });

  const formik = useFormik({
    initialValues: {
      reason: '',
      message: '',
    },
    onSubmit: () => {
      //   handleApproval();
    },
    validationSchema: validationReasonSchema,
  });

  return (
    <Box
      sx={{
        padding: '20px',
        maxWidth: 800,
        margin: 'auto',
        bgcolor: '#f9f9f9',
        justifyContent: 'center',
      }}>
      <Typography style={{ marginBottom: '20px', fontSize: '22px', fontWeight: '500' }}>
        Approval Request: Product Manager Position
      </Typography>

      <Typography style={{ marginBottom: '15px' }}>Position Details:</Typography>

      <TableContainer component={Paper}>
        <Table>
          <TableBody>
            <TableRow>
              <TableCell sx={{ fontWeight: 'bold' }}>Job Title</TableCell>
              <TableCell style={{ color: '#1976D2', fontSize: '16px', fontWeight: '600' }}>
                Product Manager
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell sx={{ fontWeight: 'bold' }}>Experience</TableCell>
              <TableCell>5 - 7 Years</TableCell>
            </TableRow>
            <TableRow>
              <TableCell sx={{ fontWeight: 'bold' }}>Primary Skills</TableCell>
              <TableCell>
                SSIS, DAX, Power BI, Data Analytics, PowerShell, Data Visualization, Power Automate
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell sx={{ fontWeight: 'bold' }}>Location</TableCell>
              <TableCell>Hyderabad</TableCell>
            </TableRow>
            <TableRow>
              <TableCell sx={{ fontWeight: 'bold' }}>Hiring Manager</TableCell>
              <TableCell style={{ color: '#1976D2', fontSize: '16px', fontWeight: '600' }}>
                Kalyan Achanta
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell sx={{ fontWeight: 'bold' }}>Salary Range</TableCell>
              <TableCell>INR 8 - 10 LPA</TableCell>
            </TableRow>
            <TableRow>
              <TableCell sx={{ fontWeight: 'bold' }}>Open Positions</TableCell>
              <TableCell>2</TableCell>
            </TableRow>
            <TableRow>
              <TableCell sx={{ fontWeight: 'bold' }}>Roles & Responsibilities</TableCell>
              <TableCell>
                <ul style={{ paddingLeft: 20, margin: 0 }}>
                  <li>Lead product development from concept to deployment.</li>
                  <li>
                    Coordinate with cross-functional teams to ensure product requirements are met.
                  </li>
                  <li>
                    Manage product backlog and prioritize features to align with business
                    objectives.
                  </li>
                  <li>Conduct market research to ensure competitive product offerings.</li>
                </ul>
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>

      <Box sx={{ display: 'flex', gap: 2, mt: 4 }}>
        <Button
          style={{
            backgroundColor: '#1976D2',
            color: '#FFF',
            padding: '1rem 2.5rem',
            borderRadius: '0.3125rem',
            fontSize: '16px',
            fontWeight: '600',
          }}>
          Approve
        </Button>
        <Button
          style={{
            backgroundColor: '#FFF',
            color: '#1976D2',
            border: '2px solid #1976D2',
            padding: '1rem 2.5rem',
            borderRadius: '0.3125rem',
            fontSize: '16px',
            fontWeight: '600',
            marginLeft: '20px',
          }}
          onClick={handleClickOpen}>
          Decline
        </Button>

        <Dialog open={open} onClose={handleClose} maxWidth='sm' fullWidth>
          <DialogTitle>
            We’re sorry to hear that you would like to decline. Can you help us understand why?
          </DialogTitle>
          <DialogContent>
            {/* <TextField
              select
              label='Select Reason *'
              value={reason}
              onChange={(e) => setReason(e.target.value)}
              fullWidth
              margin='normal'>
              {reasons.map((option) => (
                <MenuItem key={option} value={option}>
                  {option}
                </MenuItem>
              ))}
            </TextField> */}
            <div style={{ marginBottom: '1rem' }}>
              <label
                htmlFor='reason'
                style={{ display: 'block', marginBottom: '0.5rem', fontWeight: 'bold' }}>
                Select Reason *
              </label>
              <Select
                name='reason'
                id='reason'
                style={{
                  width: '100%',
                  padding: '0.35rem',
                  fontSize: '1rem',
                  borderRadius: '4px',
                }}
                value={formik.values.reason}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}>
                <option value='' disabled>
                  Choose or type
                </option>
                <MenuItem value='Budget Constraints'>Budget Constraints</MenuItem>
                <MenuItem value='Lack of Strategic Alignment'>Lack of Strategic Alignment</MenuItem>
                <MenuItem value='Role Duplication'>Role Duplication</MenuItem>
                <MenuItem value='Unclear ROI'>Unclear ROI</MenuItem>
                <MenuItem value='Market or Economic Conditions'>
                  Market or Economic Conditions
                </MenuItem>
                <MenuItem value='Performance Concerns in Existing Departments'>
                  Performance Concerns in Existing Departments
                </MenuItem>
                <MenuItem value='Overlapping Responsibilities with Other Roles'>
                  Overlapping Responsibilities with Other Roles
                </MenuItem>
                <MenuItem value='Short-Term Focus'>Short-Term Focus</MenuItem>
                <MenuItem value='Reputation and Cultural Fit'>Reputation and Cultural Fit</MenuItem>
                <MenuItem value='Other'>Other</MenuItem>
              </Select>
              {formik.touched.reason && formik.errors.reason ? (
                <div className='err-msg'>{formik.errors.reason}</div>
              ) : null}
            </div>
            <TextareaAutosize
              name='message'
              id='message'
              placeholder='Add your message here...'
              minRows={5}
              style={{
                width: '99%',
                fontSize: '1rem',
                border: '1px solid #ccc',
                borderRadius: '4px',
                resize: 'vertical',
                margin: '1rem 0',
                padding: '1rem, 0',
              }}></TextareaAutosize>
            {formik.touched.message && formik.errors.message ? (
              <div className='err-msg'>{formik.errors.message}</div>
            ) : null}
          </DialogContent>
          <DialogActions>
            <Button variant='contained' color='primary' onClick={handleSubmit}>
              Submit
            </Button>
            <Button variant='outlined' onClick={handleClose}>
              Cancel
            </Button>
          </DialogActions>
        </Dialog>
      </Box>

      <Box sx={{ mt: 4 }}>
        <Typography variant='h6' gutterBottom>
          Next Steps upon approval:
        </Typography>
        <List>
          <ListItem>
            1. <strong>Job Posting:</strong> Publish the job on internal and external platforms.
          </ListItem>
          <ListItem>
            2. <strong>Candidate Sourcing:</strong> Screen applications and source candidates.
          </ListItem>
          <ListItem>
            3. <strong>Preliminary Interview:</strong> Conduct initial interviews to shortlist
            qualified candidates.
          </ListItem>
          <ListItem>
            4. <strong>Technical Assessment:</strong> Arrange a skills test, if needed.
          </ListItem>
          <ListItem>
            5. <strong>In-Depth Interviews:</strong> Hold interviews with hiring managers and key
            stakeholders.
          </ListItem>
          <ListItem>
            6. <strong>Offer and Negotiation:</strong> Extend an offer and negotiate terms if
            necessary.
          </ListItem>
          <ListItem>
            7. <strong>Onboarding:</strong> Prepare for the candidate&apos;s onboarding process.
          </ListItem>
        </List>
      </Box>
    </Box>
  );
};

export default TadJobDefinitionApprovalRequest;
