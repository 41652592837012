import { Paper } from '@mui/material';
import React from 'react';
import TadTimeLine from '../common/timeline/tad-timeline';

interface Props {
  _value: any;
}

const CandidateActivityTracking = ({ _value }: Props) => {
  return (
    <>
      <Paper
        className='paper'
        sx={{
          maxHeight: '50rem',
          marginBottom: '1rem',
        }}>
        <div className='header-container'>
          <div className='header-content-title '>
            <span>History</span>
          </div>
        </div>
        <TadTimeLine events={_value} parent='sjd' />
      </Paper>
    </>
  );
};

export default CandidateActivityTracking;
