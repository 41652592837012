import { Table, TableBody, TableCell, TableHead, TableRow, Paper } from '@mui/material';
import { useEffect, useState } from 'react';
import { getAllTadSjd } from '../../../service/tadSjdService';
import globalObject from '../../../../common/global-variables';
import { getCandidate } from '../../../service/tadCandidateService';
import { useNavigate, useParams } from 'react-router-dom';
import { TadCandidateDetailsModel } from '../../../models/tad-candidate-form-model';

interface Skill {
  id: number;
  name: string;
}

interface SJD {
  id: number;
  name: string;
  minExperienceLevel: number | null;
  maxExperienceLevel: number | null;
  jobLocation: string;
  primarySkills: { skill: Skill }[];
}

const CandidatesAttachments: React.FC = () => {
  let { candidateId } = useParams();
  if (candidateId) candidateId = atob(candidateId);

  const navigate = useNavigate();

  const [sjdList, setSjdList] = useState<SJD[]>([]);
  const [candidate, setCandidate] = useState<TadCandidateDetailsModel | null>(null);
  const [filterJobMatching, setFilterJobMatching] = useState<SJD[]>([]);

  const handleRowClick = (jobId: any) => {
    const encode = btoa(jobId.toString());
    navigate(`/job-definition-details/${encode}/${btoa('false')}`);
  };

  useEffect(() => {
    getAllTadSjd(globalObject.userObject.id).then((res) => {
      setSjdList(res.data);
    });
    getCandidate(null, candidateId).then((res) => {
      setCandidate(res.data);
    });
  }, [candidateId]);

  useEffect(() => {
    if (candidate && sjdList.length) {
      const matchedJobs = sjdList.filter((job) => {
        const experienceMatch =
          candidate.totalExperience &&
          (job.minExperienceLevel || 0) <= candidate.totalExperience &&
          candidate.totalExperience <= (job.maxExperienceLevel || Infinity);

        const skillsMatch =
          candidate.skills &&
          candidate.skills.some((candidateSkill) =>
            job.primarySkills.some(
              (jobSkill) => jobSkill.skill.name.toLowerCase() === candidateSkill.name.toLowerCase(),
            ),
          );
        const locationMatch =
          candidate?.currentLocation?.toLowerCase() === job?.jobLocation?.toLowerCase();

        const roleMatch =
          candidate.currentRole && candidate.currentRole.toLowerCase() === job.name.toLowerCase();

        return experienceMatch && skillsMatch && locationMatch && roleMatch;
      });

      setFilterJobMatching(matchedJobs);

      console.log('filteringJobs', filterJobMatching);
    }
  }, [candidate, sjdList]);

  return (
    <Paper style={{ height: '33.6875rem', width: '100%' }}>
      <div
        style={{
          height: '61px',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          backgroundColor: '#E7E7E7',
        }}>
        <div style={{ display: 'flex', position: 'sticky' }}>
          <span
            className='heading'
            style={{ height: '24px', fontSize: '18px', marginLeft: '1.81rem' }}>
            Matching Jobs
          </span>
        </div>
      </div>
      <div className='qc-table' style={{ margin: '0 1.25rem' }}>
        <Table style={{ width: '100%' }}>
          <TableHead style={{ borderBottom: '0.125rem solid #C1C0C0' }}>
            <TableRow>
              <TableCell>Job Name</TableCell>
              <TableCell>Skills</TableCell>
              <TableCell>Experience</TableCell>
              <TableCell>Location</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {filterJobMatching.map((job) => (
              <TableRow key={job.id}>
                <TableCell
                  style={{
                    fontSize: '1rem',
                    color: 'rgba(25, 118, 210, 1)',
                  }}>
                  <span
                    style={{
                      display: 'block',
                      fontSize: '14px',
                      color: '#868686',
                      marginBottom: '0.5rem',
                    }}>
                    {`TAD${job.id}`}
                  </span>
                  <span
                    style={{
                      display: 'block',
                      fontSize: '16px',
                      cursor: 'pointer',
                      fontWeight: '600',
                      textDecorationLine: 'underline',
                    }}
                    onClick={() => handleRowClick(job.id)}>
                    {job.name}
                  </span>
                </TableCell>
                <TableCell>
                  {job.primarySkills.map((primarySkill) => primarySkill.skill.name).join(', ')}
                </TableCell>
                <TableCell>
                  {job.minExperienceLevel} - {job.maxExperienceLevel} Years
                </TableCell>
                <TableCell>{job.jobLocation}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </div>
    </Paper>
  );
};

export default CandidatesAttachments;
