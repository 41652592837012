import {
  Autocomplete,
  Button,
  FormControl,
  // Input,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  AlertColor,
  List,
  Typography,
  // IconButton,
} from '@mui/material';
import { useEffect, useState } from 'react';
import { Box } from '@mui/system';
import * as Yup from 'yup';
import { FormikProps, useFormik } from 'formik';
import './users-settings';
import { AxiosError } from 'axios';
import { ROLES } from '../../constants/common';
import globalObject from '../../../common/global-variables';
import { LookupTypes } from '../../../common/lookupTypes';
import { addUser, getAllUsers, updateUser } from '../../../service/userService';
import { getIdFromName } from '../../../common/commonUtil';
import Notify from '../../common/notify';
import { TadUserDetails } from '../../models/user.model';
import { UserDetails } from '../../../models/user.model';

// import Close from '../../assets/login/close.svg';

interface Props {
  user: TadUserDetails | undefined;
  isEdit: boolean;
  handleClose: (event: any, reason: string) => void;
}

interface OptionType {
  id: number;
  name: string;
}

const AddNewUser = ({ user, isEdit, handleClose }: Props) => {
  const [filteredOptions, setFilteredOptions] = useState(
    globalObject.lookupvalues[LookupTypes.TAD_ROLE],
  );
  const [roles, setRoles] = useState({} as any);
  const [submitted, setSubmitted] = useState(false);

  const userDetailsSchema =
    roles && roles.name === 'intern'
      ? Yup.object().shape({
          firstName: Yup.string().required('First name is required'),
          lastName: Yup.string().required('Last name is required'),
          email: Yup.string().email('Please enter a valid email').required('Email is required'),
          operations: Yup.number().required('Operations is required'),
          roles: isEdit
            ? Yup.array().required('Role is required')
            : Yup.object().nullable().required('Role is required'),
          level: Yup.string().required('level is required'),
          managerId: Yup.number()
            .integer('The value must be a number')
            .typeError('The value must be a number')
            .min(0, 'Value must be positive')
            .required('Manager is required'),
        })
      : Yup.object().shape({
          firstName: Yup.string().required('First name is required'),
          lastName: Yup.string().required('Last name is required'),
          email: Yup.string().email('Please enter a valid email').required('Email is required'),
          operations: Yup.number().required('Operations is required'),
          roles: isEdit
            ? Yup.array().required('Role is required')
            : Yup.object().nullable().required('Role is required'),
        });
  const [showApiResponseNotification, setShowApiResponseNotification] = useState(false);
  const [managers, setManagers] = useState([] as any[]);
  const [apiResponseNotification, setApiResponseNotification] = useState({
    severity: 'success',
    msg: '',
  } as {
    severity: AlertColor;
    msg: any;
  });
  const formik = useFormik({
    initialValues: user || {
      id: undefined,
      firstName: '',
      lastName: '',
      email: '',
      operations: '',
      roles: isEdit ? [] : null,
      level: '',
      managerId: '',
    },
    onSubmit: () => {
      const apiCall: Promise<any> = isEdit
        ? updateUser(formik.values as TadUserDetails, true)
        : addUser(formik.values as UserDetails, true);
      apiCall
        .then(() => {
          handleClose({}, 'submitted');
        })
        .catch((error: AxiosError) => {
          if (error.response !== undefined && error.response.data !== undefined) {
            const data: any = error.response.data;
            setApiResponseNotification({
              severity: 'error',
              msg:
                data.message !== undefined
                  ? data.message
                  : 'Something went wrong, please try again!',
            });
            setShowApiResponseNotification(true);
          }
        });
    },
    validationSchema: userDetailsSchema,
    validateOnBlur: true,
    enableReinitialize: true,
  });

  useEffect(() => {
    getAllUsers(getIdFromName(ROLES.MANAGER, LookupTypes.ROLE)).then((res) =>
      setManagers(res.data),
    );
  }, []);

  function getError(formik: FormikProps<any>, fieldName: string) {
    if ((submitted || formik.touched[fieldName]) && formik.errors[fieldName])
      return formik.errors[fieldName]?.toString();
    return null;
  }

  const handleRoleChange = (event: any, newValue: any) => {
    formik.setFieldValue('roles', isEdit ? [newValue] : newValue);
    setRoles(newValue);
    if (newValue && newValue.name === ROLES.INTERN) {
      formik.setFieldValue('managerId', '');
      setFilteredOptions(
        globalObject.lookupvalues[LookupTypes.ROLE].filter(
          (role: any) => role.name !== ROLES.MANAGER,
        ),
      );
    } else if (newValue && newValue.name === ROLES.MANAGER) {
      formik.setFieldValue('internId', '');
      setFilteredOptions(
        globalObject.lookupvalues[LookupTypes.ROLE].filter(
          (role: any) => role.name !== ROLES.INTERN,
        ),
      );
    } else {
      setFilteredOptions(globalObject.lookupvalues[LookupTypes.TAD_ROLE]);
    }
  };

  return (
    <>
      <Box role='presentation' sx={{ width: '43.75rem', height: '64rem' }}>
        <List sx={{ padding: '3.19rem 1.88rem 0rem 1.69rem' }}>
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <Typography sx={{ fontWeight: 600, fontSize: '1.25rem' }}>
              {isEdit ? 'Update User' : 'Add User'}
            </Typography>

            {/* <IconButton style={{ marginLeft: '28rem' }} onClick={}>
              <img src={Close} alt='Close' />
            </IconButton> */}
          </div>
          <div
            style={{
              gap: '24.56rem',
              alignItems: 'center',
              marginBottom: '1.17rem',
            }}>
            <hr style={{ background: '#868686', marginTop: '10px' }} />
          </div>

          <form
            style={{ display: 'flex', justifyContent: 'center', marginBottom: '1rem' }}
            onSubmit={(values) => {
              formik.handleSubmit(values);
            }}>
            <table>
              <tbody>
                <tr>
                  <td>
                    <FormControl
                      error={!formik.errors.firstName}
                      variant='standard'
                      fullWidth
                      sx={{ marginBottom: '20px', marginTop: '20px' }}>
                      <TextField
                        id='fname'
                        placeholder='First Name *'
                        name='firstName'
                        className='input-field'
                        value={formik.values.firstName}
                        onChange={(e) => formik.setFieldValue('firstName', e.target.value)}
                        onBlur={formik.handleBlur}></TextField>
                      <div className='form-err-message'>{getError(formik, 'firstName')}</div>
                    </FormControl>
                  </td>
                </tr>
                <tr>
                  {' '}
                  <td>
                    <FormControl
                      error={!!formik.errors.lastName}
                      variant='standard'
                      fullWidth
                      sx={{ marginBottom: '20px' }}>
                      <TextField
                        className='input-field'
                        placeholder='Last Name *'
                        id='lname'
                        name='lastName'
                        value={formik.values.lastName}
                        onChange={(e) => formik.setFieldValue('lastName', e.target.value)}
                        onBlur={formik.handleBlur}></TextField>
                      <div className='form-err-message'>{getError(formik, 'lastName')}</div>
                    </FormControl>
                  </td>
                </tr>

                <tr>
                  <td>
                    <FormControl
                      error={!!formik.errors.email}
                      variant='standard'
                      fullWidth
                      sx={{ marginBottom: '20px' }}>
                      <TextField
                        className='input-field'
                        placeholder='Email *'
                        id='email'
                        name='email'
                        value={formik.values.email}
                        onChange={(e) => formik.setFieldValue('email', e.target.value)}
                        onBlur={formik.handleBlur}></TextField>
                      <div className='form-err-message'>{getError(formik, 'email')}</div>
                    </FormControl>
                  </td>
                </tr>
                <tr>
                  {' '}
                  <td>
                    <FormControl variant='standard' fullWidth sx={{ marginBottom: '20px' }}>
                      <Autocomplete
                        className='input-field'
                        fullWidth
                        id='user-operations-select'
                        options={globalObject.lookupvalues[LookupTypes.TAD_OPERATION]}
                        getOptionLabel={(option: any) => option.name}
                        isOptionEqualToValue={(option, value) => option.id === value.id}
                        value={
                          formik.values.operations
                            ? globalObject.lookupvalues[LookupTypes.TAD_OPERATION].find(
                                (option: OptionType) => option.id === formik.values.operations,
                              )
                            : null
                        }
                        onChange={(event, newValue) => {
                          formik.setFieldValue('operations', newValue ? newValue.id : undefined);
                        }}
                        onBlur={formik.handleBlur}
                        renderInput={(params) => (
                          <TextField {...params} placeholder='Operations *' />
                        )}
                      />
                      <div className='form-err-message'>{getError(formik, 'operations')}</div>
                    </FormControl>
                  </td>
                </tr>

                <tr>
                  <td>
                    <FormControl fullWidth className='form-control'>
                      <Autocomplete
                        className='autocomplete-input'
                        id='user-roles'
                        options={filteredOptions}
                        limitTags={1}
                        getOptionLabel={(option) => option.name}
                        isOptionEqualToValue={(option, value) => option.id === value.id}
                        filterSelectedOptions
                        defaultValue={user?.roles ? user?.roles[0] : isEdit ? [] : null}
                        onBlur={() => formik.setFieldTouched('roles', true, true)}
                        renderInput={(params) => <TextField {...params} placeholder='Roles *' />}
                        onChange={handleRoleChange}
                      />
                      <div className='form-err-message'>{getError(formik, 'roles')}</div>
                    </FormControl>
                  </td>
                </tr>
                <tr>
                  {' '}
                  {formik.values.roles && isEdit
                    ? formik.values.roles?.find((role: any) => role.name === ROLES.INTERN)
                    : formik.values.roles?.name === ROLES.INTERN && (
                        <td>
                          <FormControl variant='standard' fullWidth sx={{ marginBottom: '20px' }}>
                            <InputLabel>Level *</InputLabel>
                            <Select
                              fullWidth
                              labelId='level'
                              value={formik.values.level}
                              name='level'
                              onChange={(e: any) => {
                                formik.setFieldValue('level', e.target.value);
                              }}
                              id='user-operations-select'>
                              <MenuItem disabled selected>
                                Select
                              </MenuItem>
                              {globalObject.lookupvalues[LookupTypes.LEVEL].map((level: any) => (
                                <MenuItem key={level.id} value={level.id}>
                                  {level.name}
                                </MenuItem>
                              ))}
                            </Select>
                            <div className='form-err-message'>{getError(formik, 'level')}</div>
                          </FormControl>
                        </td>
                      )}
                </tr>
                <tr>
                  {formik.values.roles && isEdit
                    ? formik.values.roles?.find((role: any) => role.name === ROLES.INTERN)
                    : formik.values.roles?.name === ROLES.INTERN && (
                        <div style={{ marginRight: '-6px' }}>
                          <FormControl variant='standard' fullWidth>
                            <InputLabel>Manager *</InputLabel>
                            <Select
                              id='manager'
                              fullWidth
                              size='small'
                              value={formik.values.managerId}
                              onChange={(e: any) => {
                                formik.setFieldValue('managerId', e.target.value);
                              }}>
                              <MenuItem key={0} value=' ' disabled>
                                Select Manager
                              </MenuItem>
                              {managers.map((manager) => (
                                <MenuItem
                                  key={manager.id}
                                  value={
                                    manager.id
                                  }>{`${manager.firstName} ${manager.lastName}`}</MenuItem>
                              ))}
                            </Select>
                            <div className='form-err-message'>{getError(formik, 'managerId')}</div>
                          </FormControl>
                        </div>
                      )}
                </tr>
              </tbody>
            </table>
          </form>

          <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end' }}>
            {/* <Button
              className='button'
              sx={{
                width: '7.375rem',
                height: '2.5rem',
                borderRadius: '5px',
                border: '1px solid #2D7DFE',
                backgroundColor: '#FFF',
                color: 'black',
                textTransform: 'none',
                fontSize: '1rem',
                fontWeight: 400,
                marginRight: '20px',
              }}>
              Cancel
            </Button> */}
            <Button
              type='submit'
              className='button'
              onClick={() => {
                setSubmitted(true);
                formik.handleSubmit();
              }}
              sx={{
                textTransform: 'none',
                fontWeight: 400,
                fontSize: '1rem',
                width: '7.375rem',
                height: '2.5rem',
                color: 'white !important',
                background: '#2D7DFE !important',
                marginRight: '10px',
              }}>
              Save
            </Button>
          </div>
        </List>
      </Box>

      <Notify
        severity={apiResponseNotification.severity}
        open={showApiResponseNotification}
        message={apiResponseNotification.msg}
        onClose={() => setShowApiResponseNotification(false)}></Notify>
    </>
  );
};

export default AddNewUser;
